.iphone {
  position: relative;

  .image {
    z-index: 2;
    pointer-events: none;

    img {
      transition: none;
    }

    &--is-loaded {
      background-image: none !important;
    }

    background-color: transparent;
  }

  &__viewport {
    background: #000;
    position: absolute;
    top: 0;
    left: 0;

    height: 93.5%;
    width: 88%;
    margin-top: 5.5%;
    margin-left: 6%;
    box-sizing: border-box;
    overflow: hidden;

    & > * {
      border: none;
      height: 100%;
      width: 100%;
    }
  }
}
