.social-networks {
  list-style: none;
  color: white;
  display: flex;
  padding: 0;
  margin: 1em -.5em !important;

  li {
    margin: 0 .5em;
  }

  a {
    border-radius: 50%;
    display: flex;
    background: rgba(0,0,0,.4);
    height: 2em;
    width: 2em;

    &:hover {
      color: white;
    }

    .icon {
      margin: auto;
      width: 50%;
      height: 50%;
    }
  }

  &__instagram a {
    color: #e4405f;

    &:hover {
      background: #e4405f;
    }
  }

  &__facebook a {
    color: #3b5998;
    &:hover {
      background: #3b5998;
    }
  }

  &__vk a {
    color: #4d75a3;
    &:hover {
      background: #4d75a3;
    }
  }

  &__twitter a {
    color: #1da1f3;

    &:hover {
      background: #1da1f3;
    }
  }

  &--light {
    a {
      background: white;
    }
  }

  &:not(&--light) {
    a {
      color: white;
    }
  }
}
