@import 'variables.scss';

.checkbox {
  position: relative;
  display: inline-block;
  width: 1.1em;
  height: 1.1em;
  cursor: pointer;
  vertical-align: text-top;
  background-color: white;

  &__input {
    opacity: 0;
    margin: 0;
    width: inherit;
    height: inherit;
    appearance: none;
    -webkit-appearance: none;
    position: absolute;

    &:checked + .checkbox__box {
      background: $blue;
      border: none;
      transition: box-shadow.1s ease;

      &::after {
        border-color: white !important;
      }
    }
    &:checked:hover + .checkbox__box {
      box-shadow: 0 0 .7em rgba($blue, .8);
    }
  }

  &:hover &__box::after{
    border-color: #bbb;
  }


  &__box {
    height: inherit;
    width: inherit;
    pointer-events: none;
    border-radius: .15em;
    border: 2px solid #666;
    box-sizing: border-box;

    &::after {
      content: '';
      display: block;
      position: absolute;
      display: block;
      height: .29em;
      width: .65em;
      border: solid transparent;
      bottom: .4em;
      right: .13em;
      border-width: 0 0  2px 2px;
      transform: rotate(-50deg);
    }
  }

  &--error &__box {
    border-color: $red;
  }
}
