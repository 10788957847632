@import "variables.scss";

html {
  // scroll-behavior: smooth;
}

html, body, #root, .body {
  min-height: 100vh;
}

body {
  margin: 0;
  overflow-y: scroll;

  main {
    margin-bottom: 5em;
  }
}

.body {
  background: white;
  font-family: -apple-system, "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  color: $dark-gray;

  h1 {
    font-size: 3.5em;
  }

  h1 {
    font-size: 3.5em;
  }

  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
      margin-top: 1.8em;
      margin-bottom: .8em;
      line-height: 1.2em;
      letter-spacing: .003em;
  }

  h1, .h1 {
    font-size: 3.5em;
  }

  h2, .h2 {
    font-size: 2.57em;
  }

  h3, .h3 {
    font-size: 1.42em;
  }

  h4, .h4 {
    font-size: 1.24em;
  }

  p, ul, ol {
    margin: 1.2em 0;
    line-height: 1.7em;
    max-width: 50em;
  }

  video {
    display: block;
    margin: 1.8em 0;
    max-width: 100%;
  }


  // p + ul ~ ul,
  // p + ol ~ ol {
  //   padding-left: 4em;
  //   max-width: 46em;

  //   li {
  //     margin: .8em 0;
  //   }
  // }
}


@media (max-width: 800px) {
  .body {
    font-size: 16px;

    h1, .h1 {
      font-size: 2.2em;
    }

    h2, .h2 {
      font-size: 1.5em;
    }

    h3, .h3 {
      font-size: 1.2em;
    }

    h4, .h4 {
      font-size: 1em;
    }
  }
}

@media (min-width: 1200px) {
  .body {
    font-size: 16px;
  }
}
