.image {
  position: relative;
  width: 100%;
  max-width: 100%;
  display: inline-block;
  background-size: cover;
  background-position: center;
  background-color: #f6f6f6;
  overflow: hidden;

  &__spacer {
    width: 100%;
    display: block;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
    display: block;
    opacity: 0;
    will-change: opacity;
    transition: opacity.3s ease;
    image-rendering: pixelated;
  }

  &--is-loaded {
    img {
      opacity: 1;
    }
  }
}
