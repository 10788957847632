.contacts-page {



  &__row {
    .col {
      &:first-child {
        flex: 3;
      }
      &.contacts-page__contacts {
        flex: 2;
        min-width: 300px;
      }
    }
  }

  .map {
    height: 500px;
    width: 100%;
  }

  &__contacts {
    margin-left: 2em;

    .phone {
      font-size: 2em;
      margin: .4em 0;
    }

    .email {
      font-size: 1.5em;
      margin: 0;
    }

    hr {
      border: none;
      border-bottom: 1px solid #bbb;
      max-width: 150px;
      margin: 2em 0;
    }

    ul {
      list-style: none;
      padding-left: 0;

      li > .icon {
        margin-right: .4em;
      }

      span {
        color: #777;
        margin-left: 1em;
      }

      .icon {
        vertical-align: baseline;
        width: 10px;
        height: 10px;

        &--metro_line {
          color: #4CAF50;
        }

        &--metro_mcc {
          color: red;
        }

        &--walk {
          width: 8px;
          height: 13px;
        }
      }
    }
  }

  &__staff {
    padding: 0;
    overflow: hidden;
    margin: 4em -1em !important;
    list-style: none;
    max-width: 100% !important;

    li {
      display: inline-block;
      margin: 0 3em 2em;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      .image {
        width: 250px;
      }

      .desc {
        margin: .4em 1em;

        h3 {
          display: block;
          margin: .4em 0 .5em;
        }

        .position {
          display: block;
          font-style: italic;
        }

        .link {
          display: block;
        }

        .phone {
          color: #888;
          display: block;
        }
      }
    }
  }
}


@media (max-width: 700px) {
  .contacts-page {
    &__row {
      flex-flow: column-reverse;

      .map {
        height: 100vw;
      }
    }
  }
}

@media (max-width: 1024px) {
  .contacts-page {
    &__staff {
      text-align: center;
      li {
        margin: 1em !important;
      }
    }
  }
}
