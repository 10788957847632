@import "variables.scss";

.tooltip {
  pointer-events: all;
  font-size: 14px;
  max-width: 250px;
  border-radius: 5px;
  padding: .8em 1.4em;
  line-height: 1.5em;
  background: rgba(0,0,0,.92) !important;
  opacity: 1;


  &--image {
    padding: .8em;

    .image, img {
      width: 12em;
      height: 12em;
      display: block;
    }
  }
}

.Header__title {
  text-align: center;
  background: $light-gray;

  .section {
    height: 300px;
    display: flex;
  }

  h1 {
    margin: auto;
  }
}