@import "variables.scss";

.header {
  top: 0;
  position: relative;
  // position: sticky;
  z-index: 2;
  background: #eee;
  color: black;
  padding: .2em 0;
  width: 100%;
  font-size: 1.1em;

  background: #303030;
  color: white;
  // background-image: url(../../../assets/images/header-bg.jpg);
  // background-position: center;
  // background-size: 100% 100%;
  // background-attachment: fixed;


  &.accent {
    background: $light-gray;
    color: $dark-gray;
  }

  .link {
    color: inherit;
    text-decoration: none;
  }


  .section {
    display: flex;
    // justify-content: space-between;
    align-items: center;
  }

  &__right {
    text-align: right;
    min-width: 15em;
  }

  nav {
    margin: 0 auto;
    padding-left: 2em;

    ul {
      padding: 0;
      display: flex;
      list-style: none;

      li {
        display: block;
        padding: 0 .9em;

        &:hover ul {
          opacity: 1;
          pointer-events: all;
        }

        & > span {
          cursor: pointer;
          display: flex;
          align-items: baseline;

          &::after {
            position: relative;
            margin-left: .5em;
            content: '';
            width: 0;
            display: block;
            border: solid .25em transparent;
            border-top-color: currentColor;
            opacity: .7;
            bottom: -.09em;
          }
        }
      }
    }

    & > ul > li {
      & > a,
      & > span{
        display: flex;
        padding: .1em .8em;
        border-radius: 1em;

        &:hover {
          background: rgba(255,255,255,.2);
        }
      }
    }

    .header__sub-menu {
      position: relative;
      ul {
        color: $dark-gray;
        top: 1.5em;
        border-radius: .5em;
        padding: 1em .8em;
        display: block;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        background: #fff;
        min-width: 15em;
        margin-left: -1.9em;
        box-shadow: 0 .5em 4em rgba(0,0,0,.1), 0 .2em 1em rgba(0,0,0,.1);

        &::before {
          top: -.8em;
          left: 3em;
          position: absolute;
          content: '';
          width: 0;
          display: block;
          border: solid .4em transparent;
          border-bottom-color: #fff;
        }

        &::after {
          content: '';
          display: block;
          height: 1.5em;
          position: absolute;
          width: 100%;
          left: 0;
          top: -1em;
        }

        li {
          margin: .5em 0;
          display: block;
        }
      }
    }
  }

  &__menu-button {
    position: absolute;
    right: 0;
    // top: calc((4em - calc(1em + 6px)) / 2);
    right: 1em;
    display: none;
    margin-left: auto;
    width: 2em;
    height: calc(1em + 6px);

    span {
      transition: all.3s ease;
      display: block;
      margin-top: .5em;
      height: 2px;
      width: 100%;
      background: currentColor;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  &--menu-open {
    .header__menu-button {
      position: fixed;

      span {
        &:first-child {
          transform: rotate(45deg) translate(.45em, .47em);
        }
        &:nth-child(2) {
          opacity: 0;
          transform: scale(0, 1);
        }
        &:last-child {
          transform: rotate(-45deg) translate(.45em, -.47em);
        }
      }
    }
  }
}

@media (max-width: 930px) {
  .header {
    &__right {
      display: none;
      opacity: .5;
    }

    &__sub-menu {
      ul {
        right: 0;

        &::before {
          left: auto !important;
          right: 1.6em !important;
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .header {
    &__menu-button {
      display: block;
      z-index: 2;
    }

    &__right {
      display: none;
    }

    nav {
      display: none;
      margin: 0;
      position: fixed;
      width: 100vw;
      left: 0;
      top: 0;
      height: 100%;
      padding-left: 0;
      background: rgba(0,0,0,.5);

      ul {
        box-sizing: border-box;
        display: block;
        text-align: center;
        font-size: 1.5em;
        margin: 0;
        padding: 3em 0;
        // height: 80%;
        background: white;
        color: black;

        li {
          padding: .8em;
        }
      }
    }

    &__sub-menu {
      display: none !important;
    }

    &--menu-open {
      .header__menu-button {
        color: black;
      }

      nav {
        display: block;
      }
    }
  }
}
