.tour-screen {
  position: relative;
  height: 100%;

  canvas {
    position: absolute;
    top: 0; left: 0;
    display: block;
    width: 100% !important;
    height: 100% !important;
  }
}
