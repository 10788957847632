@import 'variables.scss';

.input-range {
  height: 1rem;
  position: relative;
  width: 100%;
  margin: 2.8em 0 2em;

  &__slider {
    appearance: none;
    background: $blue;
    border: 1px solid $blue;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    height: 1rem;
    margin-left: -0.5rem;
    margin-top: -0.65rem;
    outline: none;
    position: absolute;
    top: 50%;
    // transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    width: 1rem;

    &:active {
      transform: scale(1.3);
    }

    &:focus {
      box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
    }
  }

  &--disabled &__slider {
    background: #cccccc;
    border: 1px solid #cccccc;
    box-shadow: none;
    transform: none;
  }

  &__slider-container {
    // transition: left 0.3s ease-out;
  }

  &__label {
    color: #aaaaaa;
    font-family: "Helvetica Neue", san-serif;
    font-size: 0.8rem;
    transform: translateZ(0);
    white-space: nowrap;

    &--min,
    &--max {
      bottom: -1.4rem;
      position: absolute;

      .input-range__label-container {
        left: 0;
      }
    }

    &--min {
      left: 0;
    }

    &--max {
      right: 0;
    }

    &--value {
      position: absolute;
      top: -2em;
      color: black;
      font-size: 1.1em;
    }
  }

  &__label-container {
    left: -50%;
    position: relative;
  }

  &__track {
    background: #eeeeee;
    border-radius: 0.3rem;
    cursor: pointer;
    display: block;
    height: 0.3rem;
    position: relative;
    // transition: left 0.3s ease-out, width 0.3s ease-out;

    &--background {
      left: 0;
      margin-top: -0.15rem;
      position: absolute;
      right: 0;
      top: 50%;
    }

    &--active {
      background: $blue;
    }
  }

  &--disabled &__track {
    background: #eeeeee;
  }
}
