.gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: .5fr;
  grid-gap: .8em;
  margin: 2em 0 1.5em;

  .big {
    grid-column-start: span 2;
    grid-row-start: span 2;
  }

  .high {
    grid-row-start: span 2;
  }

  .wide {
    grid-column-start: span 2;
  }

  &--simple {
    grid-auto-rows: .9fr;

    .project-card {
      grid-row-start: span 1;
      grid-column-start: span 1;
    }
  }

  &__wrapper {
    // background: #f0f;
  }

  &__load-more {
    display: block;
    margin: 2em auto 5em;
  }
}

@media (max-width: 800px) {
  .gallery {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: .8fr;

    .big {
      grid-column-start: span 1;
      grid-row-start: span 1;
    }

    .high {
      grid-row-start: span 1;
    }

    .wide {
      grid-column-start: span 1;
    }
  }
}

@media (max-width: 500px)  {
  .gallery {
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: 0.7fr;
  }
}
