.section {
  box-sizing: border-box;
  border: solid transparent;
  border-width: 1px 0;
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  padding: 0 1em;
}

@media (min-width: 700px) {
  .section {
    padding: 0 3em;
  }
}
