@import 'variables.scss';

.steps {
  &__list {
    padding: 0;
    display: flex;
    list-style: none;
    max-width: 100% !important;
    margin: 4em -1.5em 2em !important;


    &__number {
      position: relative;
      margin-bottom: 2em;

      span {
        position: relative;
        z-index: 1;
        height: 2.5em;
        width: 2.5em;
        background: $blue;
        color: white;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        box-shadow: 0 0 0 .3em white;
        font-size: 1.3em;
      }

      &::after {
        top: 50%;
        margin-top: -1px;
        position: absolute;
        content: '';
        display: block;
        height: 2px;
        width: calc(100% + 3em);
        background: $blue;
      }


    }


    li {
      flex: 1;
      margin: 0 1.5em;

      &:last-child {
        .steps__list__number::after {
          display: none;
        }
      }
    }

    strong {
      font-size: 1.3em;
    }

    p {
      margin-top: .5em;
    }
  }
}


@media (max-width: 800px) {
  .steps {
    &__list {
      flex-direction: column;
      li {
        display: flex;

        .steps__list__number {
          span {
            font-size: 1em;
          }

          &::after {
            width: 2px;
            height: 100%;
            top: auto;
            left: 50%;
            margin-left: -1px;
            margin-top: 0;
          }
        }

        .steps__list__content {
          margin-left: 1.5em;
          margin-bottom: 2.5em;
        }
      }
    }
  }
}
