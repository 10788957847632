.main-page {
  &__view-more {
    text-align: right;

    .link {
      padding: 1em 0;
    }
  }

  main > .section {
    margin: 2.5em auto;
  }
}

@media (max-width: 800px) {
  .main-page__tariffs {
    flex-direction: column;
  }
}
