.textarea {
  box-sizing: border-box;
  outline: none;
  border-radius: 2px;
  border: 1px solid #ccc;
  padding: .6em .8em;
  font-size: inherit;
  resize: vertical;

  &--error {
    border-color: red;
    color: red;

    &::placeholder {
      color: red;
    }
  }
}
