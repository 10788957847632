@import "variables.scss";

.button {
  font-size: inherit;
  text-align: center;
  line-height: 1.3em;
  background-color: $accent;
  background-image: linear-gradient(to top, darken($accent, 2%), lighten($accent, 5%));
  border: none;
  padding: .75em 2em;
  border: 1px solid darken($accent, 3%);
  outline: none;
  border-radius: 1.5em;
  cursor: pointer;
  transition: background-color.5s ease, box-shadow.5s ease;
  color: black;
  will-change: background-color, box-shadow;

  &:hover {
    background-color: lighten($accent, 8%);
    box-shadow: 0px .1em 1em 0px rgba($accent, .3);
    background-image: none;
  }

  &:active {
    background-color: $accent;
    transition: none;
  }

  &--big {
    font-size: 1.5em;
  }

  &--small {
    padding: .5em 1.2em;
  }

  &--backlight {
    box-shadow: 0px .5em 4em 0px rgba($accent, .3);
  }

  &.loading {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      display: block;
      height: 10px;
      width: 10px;
      border: 2px solid #777;
      border-right-color: transparent;
      border-radius: 7px;
      animation: rotating 1s infinite linear;
    }
  }

  &--dark {
    background: black;
    border-color: black;
    color: white;

    &:hover {
      box-shadow: none;
      background: black;
    }
  }

  &--lite {
    background: transparent;
    border-color: currentColor;
    color: currentColor;
    // border-color: $blue;
    // color: $blue;

    &:hover {
      box-shadow: none;
      background: rgba(0,0,0,.01);
    }
  }
}


@media (max-width: 800px) {
  .button {
    &--big {
      font-size: 1.2em;
    }
  }
}
