.browser {
  position: relative;

  &__viewport {
    top: calc(75 / 657 * 100%);
    left: calc(56 / 840 * 100%);
    position: absolute;
    width: calc(729 / 840 * 100%);
    height: calc(507 / 657 * 100%);

    border-bottom-right-radius: .8%;
    border-bottom-left-radius: .8%;
    overflow: hidden;

    & > * {
      border: none;
      height: 100%;
      width: 100%;
    }
  }

  .image--is-loaded {
    background: none !important;
  }
}
