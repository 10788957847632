@import "variables.scss";

.footer {
  color: $dark-gray;
  background: #fbfbfb;
  // border-top: 1px solid rgba(0,0,0,.1);
  padding: 2.5em 0;

  .logo__link {
    text-decoration: none;
  }

  .row {
    flex-wrap: wrap;
  }

  p {
    line-height: 1.5em;
  }

  &__main-column {
    flex: 1;
  }

  &__links-column {
    min-width: 16%;

    b {
      display: block;
      margin-bottom: 1.5em;
    }

    .link {
      text-decoration: none;
      text-decoration-color: rgba(0,0,0,.35);
      color: inherit;
      display: block;
      margin-top: .7em;
      opacity: .75;

      &:hover {
        opacity: 1;
      }
    }
  }


  .copyright {
    opacity: .6;
  }
}


@media (max-width: 500px)  {
  .footer {
    .col {
      margin-bottom: 2em;
    }

    &__links-column {
      b {
        margin-bottom: .8em;
      }
    }
    &__main-column {
      min-width: calc(100% - 2em);
      // margin: 1em 0;
    }
  }
}
