@import "variables.scss";

.logo {
  display: inline-flex;
  align-items: center;

  .icon {
    margin: auto;
    width: 4em;
    height: 4em;
  }

  &__title {
    font-weight: bold;
    margin-left: .7em;
  }

  .logo__wrapper {

  }

  &--accent {

    .logo__wrapper {
      display: flex;
      background: $accent;
      border-radius: 50%;
      width: 4em;
      height: 4em;
      color: black;

      .icon {
        width: 3em;
        height: 3em;
      }
    }

    &.logo--small {
      .logo__wrapper {
        width: 3em;
        height: 3em;

        .icon {
          width: 2em;
          height: 2em;
        }
      }
    }
  }

  &--small {
    .icon {
      width: 3em;
      height: 3em;
    }
  }

  &__link {
    color: inherit !important;
    text-decoration: none;

    &:hover, &:active {
      color: inherit;
    }
  }
}
