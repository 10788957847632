.cardboard {
  position: relative;

  .parallax-inner {
    height: 100%;
  }

  .image {
    z-index: 2;
    pointer-events: none;

    img {
      transition: none;
    }

    &--is-loaded {
      background-image: none !important;
    }
    background-color: transparent;
  }

  &__viewport {
    padding: 0 16px;
    box-sizing: border-box;
    background-color: #382515;
    display: flex;
    position: absolute;
    top: calc(93 / 420 * 100%);
    left: calc(114 / 596 * 100%);
    height: calc(240 / 420 * 100%);
    width: calc(393 / 596 * 100%);
  }

  &__left,
  &__right {
    flex: 1;
    padding: 17% 7%;

    & > * {
      box-sizing: border-box;
      height: 100%;
      width: 100%;
    }
  }
}
