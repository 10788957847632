@import 'variables.scss';
.calc-popup {
  .dialog__body {
    position: relative;
    // font-size: 1.1em;
    width: 27em;
    // max-height: 70vh;
    border-radius: 5px;
    padding: 1.8em;
  }

  &__tariff-info {
    display: block;
    font-size: .9em;
    margin-top: -.3em;
  }

  &__price {
    background: #eee;
    margin: 2em -1.8em 0;
    padding: 1.6em 1.8em;

    .price {
      font-size: 3.5em;
      font-weight: bold;
    }

    p {
      margin: 0;
      line-height: 1.5em;
      opacity: .7;
      font-size: .9em;
    }
  }

  &__order-button {
    text-align: center;
    margin-top: 1.8em;
    padding: .35em;
  }

  &__order-form {
    margin: -1.8em;
    margin-top: 0;
    padding: 1.8em;

    h3 {
      margin-top: 0;
    }

    .textfield,
    .textarea {
      display: block;
      width: 100%;
      margin: 1em 0;
      background: #fafafa;
    }

    .textarea {
      min-height: 5em;
    }
  }

  h2 {
    margin-top: .5em;
  }

  .services-list {
    label {
      display: block;
      margin: .8em 0;

      .checkbox {
        margin-right: .5em;
      }

      .price {
        // &::before {
        //   content: '';
        // }
        margin-left: 1em;
        opacity: .6;
        font-size: .9em;
      }
    }
  }

  &__close-button {
    position: absolute;
    cursor: pointer;
    top: 1.4em;
    right: 1.4em;
    height: 1em;
    width: 1em;

    .icon {
      width: inherit;
      height: inherit;
    }
  }
}
