.dialog {
  z-index: 9;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,.85);
  animation: dialog-show.25s linear;
  will-change: background;
  overflow-y: scroll;
  padding: 5em 0;

  &__body {
    will-change: opacity, transform;
    animation: dialog-body-show .5s ease;
    height: inherit;
    min-height: inherit;
    margin: auto;
    box-shadow: 0 4px 32px rgba(0,0,0,.3);
  }
}

@keyframes dialog-show {
  from {
    background: rgba(0,0,0,.0);
  }
  to {
    background: rgba(0,0,0,.85);
  }
}

@keyframes dialog-body-show {
  from {
    opacity: 0;
    transform: translate(0, 150px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0px);
  }
}
