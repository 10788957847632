@import 'variables.scss';

.player-pluses {

  .title {
    text-align: center;
    margin-bottom: 2em;
  }
  ul {
    margin-top: 3em;
    max-width: 100%;
    padding: 0;
    list-style: none;

    display: grid;
    // grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 3em;

    li {
      text-align: left;

      strong {
        font-size: 1.2em;
      }

      p {
        text-align: left;
        margin-top: .7em;
      }

      .icon {
        display: block;
        width: 3.2em;
        height: 3.2em;
        margin-bottom: 1em;

        // color: $blue;
      }
    }
  }
}


@media (max-width: 800px) {
  .player-pluses {
    ul {
      grid-template-columns: 1fr 1fr;
      grid-gap: 2em;
    }
  }
}
@media (max-width: 550px) {
  .player-pluses {
    ul {
      grid-template-columns: 1fr;
      grid-gap: 1em;
    }
  }
}
