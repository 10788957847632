@import "variables.scss";

.tag {
  display: inline-block;
  border-radius: .2em;
  padding: .3em .8em;
  margin-right: .8em;
  margin-bottom: 1em;
  background: rgba(0,0,0,.05);
  cursor: pointer;
  color: rgba(0,0,0,.64);

  &:hover {
    background: rgba(0,0,0, .08);
  }

  &:active {
    background: rgba(0,0,0, .17);
  }

  &--active {
    background: rgba($blue, .15);
    color: $blue;

    &:hover {
      background: rgba($blue, .2);
    }

    &:active {
      background: rgba($blue, .25);
    }
  }

  &__cancel {
    vertical-align: baseline;
    margin-left: .5em;
    height: .6em;
    width: .6em;
  }
}
