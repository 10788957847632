.map {
  height: 500px;
  width: 100%;
  background: #e5e3df;

  a[rel="noopener"] {
    display: none !important;
  }
  // .gmnoprint,
  .gm-style-cc {
    display: none;
  }
}
