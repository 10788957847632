.logos {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 2em;

  .image {
    max-width: 12em;
    margin: auto;
  }
}
