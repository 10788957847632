.project-card {
  text-align: left;
  display: block;
  margin: 0;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  transition: box-shadow.2s;
  will-change: box-shadow;

  figure {
    height: 100%;
    margin: 0;
  }

  figcaption {
    z-index: 2;
    line-height: 1.45em;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    position: absolute;
    padding: .9em 1.2em;
    bottom: 0;
    width: 100%;
    color: white;
    height: 6em;
    background-image: linear-gradient(to top,rgba(0,0,0,0.7),transparent);
  }

  &__line {
    display: flex;
  }

  &__title {
    margin-top: auto;
    font-size: 1.1em;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  p {
    margin: 0;
    margin-right: auto;
    opacity: .9;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__image {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__counters {
    display: flex;
    margin-left: 1em;

    span {
      white-space: nowrap;
      margin-left: .75em;

      .icon {
        margin-left: .1em;
        width: 1.3em;
        height: 1.3em;
      }
    }
  }

  &:hover {
    // box-shadow: 0 10px 40px rgba(0,0,0,.5);
  }

  &--placeholder {
    background: #eee;
    // background: linear-gradient(to bottom, #eee 0%, #ddd 100%);

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }


  &__tags {
    padding: 0 1.2em;
    position: absolute;
    top: 1.5em;
    width: 100%;
    box-sizing: border-box;
    z-index: 1;
    opacity: 0;

    .link {
      display: inline;
      color: #fff;
      margin-right: .7em;
      letter-spacing: .02em;
      text-decoration: none;
    }
  }

  &__button {
    position: absolute;
    bottom: 0.8em;
    opacity: 0;
    background: white;
    // background: rgba(#fff, .2);
    // color: #fff;
    border: none;

    .icon {
      vertical-align: middle;
      width: 1em;
      height: 1em;
      transform: translate(-.3em, -.1em);
      margin-left: -0.2em;
      will-change: transform;
    }
  }

  &__rating {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,.7);
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10em;
    font-weight: bold;
    color: white;
  }

  &::before {
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,.5);
    z-index: 1;
    opacity: 0;
    transition: opacity.3s ease;
    will-change: opacity;
  }


  &:hover {
    &::before {
      opacity: 1;
    }
  }

  &__description {
    will-change: transform;
  }


  &:hover & {
    &__tags {
      opacity: 1;
    }
    &__title, &__description {
      transition: all.3s ease;
      transform: translate(0, -4em);
    }

    &__button {
      transition: all.3s ease;
      bottom: 1.5em;
      opacity: 1;
    }
  }
}

@media (max-width: 1000px) {
  .project-card__counters {
    display: none;
  }
}
