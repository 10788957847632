@import 'variables.scss';

.tariffs {
  display: flex;
  flex-wrap: wrap;
  margin: 3em 0 6em;


  .price {
    font-weight: bold;
    margin-top: .5em;
  }

  .tariff {
    background: #f9f9f9;
    text-align: center;
    box-sizing: border-box;
    flex: 1;
    padding: 1.5em 2em;
    display: flex;
    flex-direction: column;
    border: 1px solid #eee;
    border-width: 1px 0;

    &:first-child {
      border-radius: 10px 0 0 10px;
      border-left-width: 1px
    }
    &:last-child {
      border-radius: 0 10px 10px 0;
      border-right-width: 1px
    }

    &--hit {
      border-radius: 10px;
      background: white;
      margin-top: -1em;
      padding-top: 2.5em;
      margin-bottom: -1em;
      padding-bottom: 2.5em;
      z-index: 1;
      border: 2px solid $blue;
    }

    &__price {
      margin: 2em 0;

      .price {
        font-size: 2em;
      }
    }

    p {
      font-size: .9em;
      text-align: left;
      opacity: .8;
      margin-bottom: 2em;
    }

    strong {
      font-size: 1.5em;
      margin-top: .8em;
    }

    .button {
      margin-top: auto;
    }
  }
}
