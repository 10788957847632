
@import 'variables.scss';

.price-page {
  .table {
    width: 100%;
    margin: 3em auto 5em;
    text-align: center;
    border-spacing: 0;

    th {
      line-height: 1.5em;

      &:first-child {
        width: 47%;
      }

      .price {
        color: #666;
        font-weight: normal;
      }
      padding: .6px 1em;
      padding-bottom: 1em;
    }

    tr {
      &:nth-child(2n) {
        background: #f6f6f6;
      }

      td:first-child {
        text-align: left;
      }
    }

    td {
      padding: .6em 1em;
    }

    .check {
      height: .9em;
      width: .9em;
      color: $blue;
    }

    .info {
      color: #444;
      height: .8em;
      width: .8em;
      margin-left: .5em;
      display: inline-block;
      vertical-align: sub;

      .icon {
        width: inherit;
        height: inherit;
      }
    }

    &__footer {
      background: transparent !important;
      td {
        padding-top: 2em;
      }
    }
  }

  &__services-list {
    display: flex;
    margin: 3em -1em;

    ul {
      flex: 1;
      padding: 0;
      list-style: none;
      margin: 0 1em;
    }

    li {
      margin-bottom: 2em;
    }

    strong {
      font-size: 1.1em;
    }

    p {
      margin-top: .3em;
    }
  }
}



@media (max-width: 800px) and (min-width: 500px) {
  .price-page__tariffs > div {
    min-width: calc(50% - 1em);
  }
}

@media (max-width: 500px) {
  .price-page__tariffs {
    margin: 0;

    & > div {
      min-width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .price-page__services-list {
    flex-direction: column;
  }
}
