@import 'variables.scss';
.offer {

  background: #f2f2f2;
  text-align: center;
  max-width: 100%;
  min-height: 30em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 5em;

  p {
    font-size: 1.5em;
    opacity: .8;
    max-width: 30em;

    strong {
      font-weight: inherit;
    }
  }

  h2 {
    margin: 0;
  }

  .button {
    font-size: 1.2em;
  }
}
