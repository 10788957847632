@import "variables.scss";

.link {
  color: $blue;
  outline: none;
  text-decoration: underline;
  text-decoration-color: rgba($blue, .5);
  text-decoration-style: dashed;
  text-underline-position: under;
  transition: color.1s ease;
  cursor: pointer;

  &:hover, &:active {
    transition: none;
    color: $red;
    text-decoration-color: rgba($red, .5);
  }

  &[href] {
    text-decoration-style: solid;
  }
}
