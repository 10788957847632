.timeline-page {
  h1, h2 {
    margin: 0;
  }

  .lavel {
    margin-left: 3em;
  }

}



.year {
  height: 5em;
  width: 100%;
  display: flex;
  margin-bottom: 200px;
  border-bottom: 1px solid #eee;

  .month {
    margin-top: auto;
    box-sizing: border-box;
    flex: 1;
    background: #eee;
  }
}
