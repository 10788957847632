.main-page__calc {
  display: flex;
  position: relative;
  justify-content: left;
  align-items: center;
  height: 26em;
  background: #f3f4f5;

  &__content {
    position: relative;
    z-index: 1;
    max-width: 50em;
    padding-left: 8em;

    h2 {
      margin-top: 0;
    }
  }

  .image {
    left: 0;
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    pointer-events: none;
  }

  .button {
    font-size: 1.2em;
  }
}



@media (max-width: 900px) {
  .main-page__calc {
    &__content {
      padding-left: 0;
    }

    .image {
      display: none;
    }
  }
}
