.player-page {
  // text-align: center;

  &__promo {
    text-align: center;

    h4 {
      max-width: 30em;
      margin: 3em auto 5.5em;
      line-height: 1.8em;
      font-weight: 500;
    }
  }

  &__player {
    position: relative;
    left: -8%;
    max-width: 840px;
    margin: 0 auto;
    position: relative;

    .cardboard__wrapper {
      pointer-events: none;
      position: absolute;
      width: 70%;
      right: -35%;
      bottom: -11%;
      z-index: 3;
    }

    .iphone__wrapper {
      position: absolute;
      right: -10%;
      bottom: 0;
      width: 30%;
    }
  }

  &__code {
    max-width: 56em;
    margin-left: auto;
    margin-right: auto;

    table {
      width: 100%;
      border-spacing: 0;
      border-collapse: collapse;

      td, th {
        text-align: left;
        padding: .2em 1em;
      }

      th {
        padding-bottom: 1em;
      }

      tr td:nth-child(2n) {
        text-align: center;
      }
    }
  }

  &__description {
    text-align: left;
    margin: 5em auto;

    p {
      margin-left: auto;
      margin-right: auto;
      text-align: inherit;
    }
  }

  &__gallery {
    text-align: center;
    .gallery {
      grid-gap: 0;

      .project-card {
        border-radius: 0;
      }
    }
  }
}
