@charset "UTF-8";
.title {
  position: relative;
  cursor: pointer; }
  .title::before {
    margin-left: -.9em;
    width: .9em;
    display: inline-block;
    font-size: .6em;
    content: '§';
    opacity: 0;
    vertical-align: text-bottom; }
  .title:hover::before {
    opacity: .3; }
