.order-popup {

  .agreement {
    &__row {
      align-items: center;
    }

    &__col {
      flex: 2 !important;
    }
  }

  .dialog__body {
    position: relative;
    font-size: 1.1em;
    width: 27em;
    max-height: 70vh;
    border-radius: 5px;
    padding: 1.8em;
    overflow: hidden;
  }

  h2 {
    margin: 1em 0 .7em;

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  &__close-button {
    position: absolute;
    cursor: pointer;
    top: 1.4em;
    right: 1.4em;
    height: 1em;
    width: 1em;

    .icon {
      width: inherit;
      height: inherit;
    }
  }


  &__form {
    border-top: 1px solid #ccc;
    background: #efefef;
    padding: 1.8em ;
    margin: 2em -1.8em -1.8em;

    .row:first-child {
      margin-top: 0;
    }

    .row .col {
      flex: 1;
    }

    .textarea,
    .textfield {
      width: 100%;
    }

    .textarea {
      display: block;
      height: 5em;
    }

    .message {
      display: inline-block;
      padding: 0 1em;
    }
  }
}
