.comparison-slider {
  position: relative;
  line-height: 0;
  cursor: col-resize;

  &__before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    overflow: hidden;
    z-index: 1;

    .image {
      max-width: initial;
      height: 100%;

      img {
        object-position: left;
      }
    }
  }

  &__separator {
    position: absolute;
    top:0;
    width: 2px;
    height: 100%;
    background: white;
    z-index: 2;
    pointer-events: none;
  }

  &:not(:hover) {
    .comparison-slider {
      &__before {
        transition: width.3s ease;
        width: 50% !important;
      }
      &__separator {
        transition: left.3s ease;
        left: 50% !important;
      }
    }
  }
}
