.promo {
  position: relative;
  display: flex;
  flex-direction: column;
  background: #012;
  min-height: 100vh;
  color: white;
  background-size: cover;
  background-position: center;
  overflow: hidden;

  .header {
    background: transparent;
    color: white;
    margin-top: 1em;

    &__right {
      .link {
        display: block;

        &.phone {
          font-size: 1.4em;
          font-weight: 600;
        }
      }
    }
  }

  &::after {
    content: '';
    display: block;
    background-color: rgba(0,2,8,0.77);
    background-image: linear-gradient(to bottom,rgba(0,0,5,0.5) 0,transparent 30%);
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    opacity: .85;
  }

  &__pano {
    border: none;
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    will-change: transform;

  }

  & > .section {
    pointer-events: none;
    flex: 1;
    position: relative;
    z-index: 1;
    border: none;

    display: flex;
    flex-direction: column;
    justify-content: center;

    padding-bottom: 2.5em;

    h1 {
      pointer-events: all;
      font-size: 4.5em;
      margin-top: .4em !important;
      margin: 0;
    }

    p {
      pointer-events: all;
      opacity: .9;
      font-size: 1.6em;
      margin: 1em 0 3em;
    }

    .button {
      pointer-events: all;
      width: max-content;
      margin-bottom: 5%;
    }
  }

  &__more {
    pointer-events: all;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    width: 8em;
    height: 4em;

    .icon {
      display: block;
      margin: auto;
      width: 1.4em;
      height: 1.4em;
      animation: arrow-bounce2 3s infinite;
    }
  }
}


@media (max-width: 800px) {
  .promo > .section {
    h1 {
      font-size: 2em;
    }
    p {
      font-size: 1em;
    }
  }
}


@keyframes arrow-bounce {
    0%, 50%, 100% {
      transform: translateY(0);
    }
    25%, 75% {
      transform: translateY(10px);
    }
}

@keyframes arrow-bounce2 {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }

    40% {
      transform: translateY(-1.6em);
    }
    60% {
      transform: translateY(-.8em);
    }
}
