.project-popup {
  padding: 0;
  overflow: hidden;

  .dialog__body {
    position: relative;
    width: 100%;
    max-width: calc(100vw - 8.5em);
    height: calc(100vh - 6.5em);
  }

  &__iframe {
    display: block;
    border: none;
    width: 100%;
    height: 100%;
  }

  &__close-button {
    cursor: pointer;
    position: absolute;
    display: flex;
    right: 0;
    color: white;
    top: -40px;
    height: 40px;
    width: 40px;

    &:hover {
      transform: scale(1.1);
    }

    &:active {
      transform: scale(0.95);
    }

    .icon {
      margin: auto;
      width: 1em;
      height: 1em;
    }
  }

  &__meta {
    padding: 0 .6em;
    display: flex;
    align-items: center;
    color: white;
    height: 50px;
    margin-bottom: -50px;

    .icon {
      width: 1.6em;
      margin-right: .8em;
    }

    b {
      font-weight: bold;
      margin-right: 1em;

      max-width: 50%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    p {
      flex: 1;
      margin: 0;
      margin-right: auto;
      opacity: .75;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__prev,
  &__next {
    position: absolute;
    display: flex;
    height: 5em;
    width: 4em;
    top: 50%;
    margin-top: -2.5em;
    cursor: pointer;
    opacity: .7;

    &:hover {
      opacity: 1;
    }

    .icon {
      margin: auto;
      height: 2.5em;
      width: 100%;
    }
  }
  &__prev {
    left: -4em;
  }
  &__next {
    right: -4em;
  }
}
