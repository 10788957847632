@import 'variables.scss';

.tabs {
  display: flex;
  margin: 1em .5px;

  &__tab {
    cursor: pointer;
    margin: 0 -0.5px;
    min-width: 5em;
    text-align: center;
    border: 1px solid $blue;
    padding: .5em 1.2em;
    color: $blue;

    &--active {
      color: white;
      background: $blue;
    }

    &:not(&--active):hover {
      background: rgba($blue, .02);
    }

    &:first-child {
      border-top-left-radius: .5em;
      border-bottom-left-radius: .5em;
    }

    &:last-child {
      border-top-right-radius: .5em;
      border-bottom-right-radius: .5em;
    }

    &__price {
      display: block;
      margin-top: .3em;
      font-size: .9em;
    }
  }
}
