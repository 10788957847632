.map-page {

  .map {
    box-sizing: border-box;
    height: 50vw;
    min-height: 25em;
    max-height: 45em;
  }

  &__map {
    padding: 0 1em;
  }

  &__links {
    margin: 2em 0;
    column-count: 4;
    column-gap: 3em;

    .link {
      grid-column: 1;
      text-decoration: underline;
      text-decoration-style: dashed;
      cursor: pointer;
      display: block;
      margin-bottom: .7em;
    }
  }
}



@media (max-width: 800px) {
  .map-page__links {
    column-count: 3;
  }
}

@media (max-width: 600px) {
  .map-page__links {
    column-count: 2;
  }
}


@media (max-width: 350px) {
  .map-page__links {
    column-count: 1;
  }
}
