@import 'variables.scss';

.pluses {
  ul {
    margin-top: 3em;
    max-width: 100%;
    padding: 0;
    list-style: none;

    display: grid;
    // grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 3em;

    li {
      strong {
        font-size: 1.2em;
      }

      p {
        margin-top: .7em;
      }

      .icon {
        display: block;
        width: 3.2em;
        height: 3.2em;
        margin-bottom: 1em;

        // color: $blue;
      }
    }
  }
}


@media (max-width: 800px) {
  .pluses {
    ul {
      grid-template-columns: 1fr 1fr;
      grid-gap: 2em;
    }
  }
}
@media (max-width: 550px) {
  .pluses {
    ul {
      grid-template-columns: 1fr;
      grid-gap: 1em;
    }
  }
}
